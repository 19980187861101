import axios from 'axios';
import { ActionTree, ActionContext } from 'vuex';
import { RootState } from '../../state';
import { MutationTypes } from './mutations';
import { MutationTypes as DbMutation } from '../db/mutations';
import userState from './state';
import activityList from './const';

// eslint-disable-next-line no-shadow
export enum ActionTypes {
  loginUser = 'loginUser',
  fetchUser = 'fetchUser',
  refreshToken = 'refreshToken',
  forcedLogout = 'forcedLogout'
}
type UserActionCtx = ActionContext<typeof userState, RootState>;

export interface UserActions extends ActionTree<typeof userState, RootState> {
  loginUser: (ctx: UserActionCtx, payload) => Promise<void>;
}

export const actions: UserActions = {
  async loginUser({ commit }, data) {
    try {
      commit(MutationTypes.LOGIN_USER, data);
      commit(`db/${DbMutation.setUser}`, data);
      return data;
    } catch (err) {
      commit(MutationTypes.LOGIN_ERROR, err);
      throw err;
    }
  },

  async refreshToken({ commit, dispatch }, data) {
    try {
      const tknData = {
        refresh_token: localStorage.getItem('apollo-refresh-token')
      };
      const resp = await axios.post(`${process.env.VUE_APP_AUTH0}/generate-access-token`, tknData, {
        headers: {
          'Content-Type': 'application/json'
        }
      });
      if (resp.data.data) {
        localStorage.setItem('apollo-token', resp.data.data.access_token);
        localStorage.setItem('apollo-refresh-token', resp.data.data.refresh_token);
        window.location.href = `/portfolio`;
      }
    } catch (error) {
      console.log(error);
    }
  },
  // eslint-disable-next-line consistent-return
  async getAuthUrl({ commit, dispatch }, type) {
    try {
      let google;
      if (type === 'google') {
        google = true;
      } else if (type === 'outlook') {
        google = false;
      }
      const response = await axios.get(
        `${process.env.VUE_APP_CALENDAR_SERVICE}/auth-url?redirect_uri=${window.location.origin}/meetings&google=${google}`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  },
  // eslint-disable-next-line consistent-return
  async refreshCalendarTokenData({ commit, dispatch }, refreshTokenData) {
    try {
      let google;
      if (refreshTokenData.type === 'google') {
        google = true;
      } else if (refreshTokenData.type === 'outlook') {
        google = false;
      }
      const response = await axios.get(
        `${process.env.VUE_APP_CALENDAR_SERVICE}/refresh-access-token?refresh_token=${refreshTokenData.refreshToken}&google=${google}`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  // eslint-disable-next-line consistent-return
  async createCalendar({ commit, dispatch }, data) {
    try {
      const response: any = await axios.post(
        `${process.env.VUE_APP_CALENDAR_SERVICE}/calendar?token=${data.accessToken}&google=${data.google}`,
        data.eventData,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      return response.data;
    } catch (error) {
      if (error.response.data.error.code === 'UNAUTHORIZED') {
        const refreshTokenData: any = await axios.get(
          `${process.env.VUE_APP_CALENDAR_SERVICE}/refresh-access-token?refresh_token=${data.refreshToken}&google=${data.google}`,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          }
        );
        const responseData: any = await await axios.post(
          `${process.env.VUE_APP_CALENDAR_SERVICE}/calendar?token=${refreshTokenData.data.data.access_token}&google=${data.google}`,
          data.eventData,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          }
        );
        return responseData.data;
      }
      console.log(error);
    }
  },
  // eslint-disable-next-line consistent-return
  async updateCalendar({ commit, dispatch }, data) {
    try {
      const response: any = await axios.patch(
        `${process.env.VUE_APP_CALENDAR_SERVICE}/calendar?token=${data.accessToken}&event_id=${data.eventId}&google=${data.google}`,
        data.eventData,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('apollo-token')}`,
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data;
    } catch (error) {
      if (error.response.data.error.code === 'UNAUTHORIZED') {
        const refreshTokenData: any = await axios.get(
          `${process.env.VUE_APP_CALENDAR_SERVICE}/refresh-access-token?refresh_token=${data.refreshToken}&google=${data.google}`,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('apollo-token')}`
            }
          }
        );
        const responseData: any = await axios.patch(
          `${process.env.VUE_APP_CALENDAR_SERVICE}/calendar?token=${refreshTokenData.data.data.access_token}&event_id=${data.eventId}&google=${data.google}`,
          data.eventData,
          {
            headers: {
              authorization: `Bearer ${localStorage.getItem('apollo-token')}`,
              'Content-Type': 'application/json'
            }
          }
        );
        return responseData.data;
      }
      console.log(error, 'update calendar error');
    }
  },
  // eslint-disable-next-line consistent-return
  async getCalendarTokenData({ commit, dispatch }, tokenData) {
    try {
      let google;
      if (tokenData.type === 'google') {
        google = true;
      } else if (tokenData.type === 'outlook') {
        google = false;
      }
      const response = await axios.get(
        `${process.env.VUE_APP_CALENDAR_SERVICE}/auth-token?code=${tokenData.code}&redirect_uri=${window.location.origin}/meetings&google=${google}`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  // eslint-disable-next-line consistent-return
  async getCalendarEvents({ commit, dispatch }, data) {
    try {
      let query;
      if (data.calendarData.calendarType === 'month') {
        query = `token=${data.accessToken}&year=${data.calendarData.year}&month=${data.calendarData.month}&google=${data.google}`;
      } else if (data.calendarData.calendarType === 'day') {
        query = `token=${data.accessToken}&year=${data.calendarData.year}&month=${data.calendarData.month}&day=${data.calendarData.day}&google=${data.google}`;
      } else if (
        data.calendarData.calendarType === '4day' ||
        data.calendarData.calendarType === 'week'
      ) {
        const weekDays = {
          start_time: data.calendarData.startDate,
          end_time: data.calendarData.endDate
        };
        query = `token=${data.accessToken}&week=${encodeURIComponent(
          JSON.stringify(weekDays)
        )}&google=${data.google}`;
      } else {
        const weekDays = {
          start_time: data.calendarData.startDate,
          end_time: data.calendarData.endDate
        };
        query = `token=${data.accessToken}&week=${encodeURIComponent(
          JSON.stringify(weekDays)
        )}&google=${data.google}`;
      }
      const response = await axios.get(
        `${process.env.VUE_APP_CALENDAR_SERVICE}/calendar?${query}`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  // eslint-disable-next-line consistent-return
  async getInternshipBadge({ commit, dispatch }, programId) {
    try {
      const response = await axios.get(
        `${process.env.VUE_APP_CALENDAR_SERVICE}/internship-badge?program_id=${programId}`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  },

  // eslint-disable-next-line consistent-return
  async deleteCalendarEvent({ commit, dispatch }, data) {
    try {
      const response = await axios.delete(
        `${process.env.VUE_APP_CALENDAR_SERVICE}/calendar?token=${data.accessToken}&event_id=${data.eventId}&google=${data.google}`,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },
  async fetchUser({ commit, dispatch }, data) {
    try {
      const resp = await axios.get(`${process.env.VUE_APP_AUTH0}/get-mongo-user`, {
        headers: {
          Authorization: `Bearer ${data}`
        }
      });
      commit(MutationTypes.LOGIN_USER, resp.data);
      commit(`db/${DbMutation.setUser}`, resp.data);
      return resp.data;
    } catch (err) {
      console.log(err);
      if (err) {
        dispatch('refreshToken', localStorage.getItem('apollo-token'));
        dispatch('forcedLogout');
      }
      commit(MutationTypes.LOGIN_ERROR, err);
      throw err;
    }
  },
  async refreshDiscordAccessToken({ commit, dispatch }, data) {
    try {
      const resp = await axios.get(
        `${process.env.VUE_APP_COWORK_SERVICE}/refresh-token?refresh_token=${data.refreshToken}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
          }
        }
      );
      return resp;
    } catch (err) {
      console.log(err.response);
      return err.response.data;
    }
  },
  // eslint-disable-next-line consistent-return
  async createDiscordChannels({ commit, dispatch }, data) {
    try {
      const resp = await axios.post(`${process.env.VUE_APP_COWORK_SERVICE}/channels`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      return resp;
    } catch (err) {
      console.log(err.response);
    }
  },
  async sendMessageInDiscord({ commit, dispatch }, data) {
    try {
      const resp = await axios.post(`${process.env.VUE_APP_COWORK_SERVICE}/send-message`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      return resp;
    } catch (err) {
      console.log(err.response);
      return err.response.data;
    }
  },
  // eslint-disable-next-line consistent-return
  async addMembersToDiscordRole({ commit, dispatch }, data) {
    try {
      const resp = await axios.post(`${process.env.VUE_APP_COWORK_SERVICE}/add-to-role`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        }
      });
      return resp;
    } catch (error) {
      console.log(error);
    }
  },

  async getAdkIndexByName({ commit, dispatch }, adkName) {
    let index = 0;
    if (activityList.includes(adkName.toLowerCase())) {
      index = activityList.indexOf(adkName.toLowerCase());
    }
    return index;
  },
  // eslint-disable-next-line consistent-return
  async getPrivacyPolicy() {
    try {
      const response = await axios.get(`https://www.iubenda.com/api/privacy-policy/32542296`);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  // eslint-disable-next-line consistent-return
  async getTermsAndConditions() {
    try {
      const response = await axios.get(`https://www.iubenda.com/api/terms-and-conditions/32542296`);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  },

  async forcedLogout() {
    localStorage.removeItem('apollo-token');
    localStorage.removeItem('apollo-refresh-token');
    window.location.href = `/login?forcedLogout=true`;
  }
};
