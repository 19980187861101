


















































































































































































import { onLogout } from '@/vue-apollo';

import { useAuthActions, useDbState } from '@/store';
import Profile from '@/components/Profile.vue';

export default {
  components: {
    Profile
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },

  setup(_props, ctx) {
    const { logout: logoutProcess } = useAuthActions(['logout']);
    const { user } = useDbState(['user']);

    const logout = async () => {
      await logoutProcess();
      await onLogout();
      // ctx.root.$router.push({ name: 'login' });
      window.location.href = '/login';
    };

    return {
      logout,
      user
    };
  },

  data() {
    return {
      menuList: false
    };
  },

  methods: {
    openMenu() {
      this.menuList = !this.menuList;
    }
  }
};
