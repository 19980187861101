




























import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import { computed, ref, watchEffect } from '@vue/composition-api';
import { useUserGetters, useDbState, useDbGetters } from '@/store';

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview,
  FilePondPluginFileEncode,
  FilePondPluginImageExifOrientation,
  FilePondPluginImageCrop,
  FilePondPluginImageResize,
  FilePondPluginImageTransform
);
interface FileType {
  source: string | null | undefined;
  options: {
    type: string;
  };
}
export default {
  components: {
    FilePond
  },
  props: {
    editable: {
      default: false,
      type: Boolean
    },
    size: {
      required: true,
      type: Number
    }
  },
  setup() {
    // init data
    const { functions } = useDbGetters(['functions']);
    const { user } = useDbState(['user']);
    const myFiles = computed(() => {
      const files: FileType[] = [];
      if ((user.value as any)?.profile)
        files.push({
          source: (user.value as any)?.profile.medium,
          options: {
            type: 'local'
          }
        });
      return files;
    });
    // mongo setup
    const userId = useUserGetters(['getId']).getId;
    const {
      getObjectId: { value: getObjectId }
    } = useUserGetters(['getObjectId']);

    const { collection } = useDbGetters(['collection']);
    const server = ref({
      process(fieldName, file, metadata, load, error, progress, abort) {
        const reader = new FileReader();
        reader.onload = (fileEvent: Event) => {
          const base64Encoded = (fileEvent.target as any)?.result;
          functions.value
            .callFunction('uploadProfileImage', userId.value, base64Encoded)
            .then(data => {
              load(data.medium); // load image to filepond from key
            })
            .catch(err => {
              // handle error
              error('Something went wrong');
            });
        };
        reader.readAsDataURL(file);
        return {
          abort: () => {
            abort();
          }
        };
      },
      load(source, load, error, progress, abort) {
        // Should request a file object from the server here
        const myRequest = new Request(source);
        fetch(myRequest)
          .then(response => {
            response.blob().then(myBlob => {
              const blob = myBlob.slice(0, myBlob.size, 'image/jpeg');
              load(blob);
            });
          })
          .catch(err => error('Something went wrong'));
        return {
          abort: () => {
            abort();
          }
        };
      },
      remove(source, load) {
        // delete profile images from user doc
        collection.value!('User').findOneAndUpdate(
          {
            _id: getObjectId
          },
          { $unset: { profile: '' } }
        );
        load();
      }
    });
    const src = ref('');
    watchEffect(() => {
      if ((user.value as any)?.profile) src.value = (user.value as any)?.profile.medium;
    });
    const initials = computed(
      () =>
        `${(user.value as any)?.firstName?.toUpperCase().charAt(0)} ${(user.value as any)?.lastName
          ?.toUpperCase()
          .charAt(0)}`
    );
    return {
      myFiles,
      server,
      user,
      src,
      initials
    };
  }
};
